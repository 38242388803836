<template>
  <component :is="layout" />
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },

  computed: {
    layout() {
      return this.$route.meta.layout || 'Layout';
    },
  },

  watch: {
    $route() {
      this.checkRoute();
    },
  },

  async created() {
    const isValid = await this.$store.dispatch('checkLoginState');
    const isInRestrictedPage = !this.$route.meta.noAuthRequired;

    if (!isValid && isInRestrictedPage) {
      this.$router.push({
        name: 'UserLogin',
        query: { message: 'Your session has expired. Please login again' },
      });
    }
  },

  methods: {
    /**
     * Check current route and do something with it
     */
    checkRoute() {
      // console.log( this.$route.name );
    },
  },
};
</script>

<style lang="sass">
// If you are using VS Code, install the "Sass" extension to properly highlight syntax
@import "./sass/vars"
@import "./sass/vars-root"
@import "./sass/normalize"
@import "./sass/main"
</style>

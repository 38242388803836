<template>
  <div class="user-form__wrapper">
    <UserForm
      :errors="errors"
      submit-button="Update Profile"
      form-cache="profileForm"
      form-endpoint="/user/profile-form"
      @submitted="updateProfile"
    />
  </div>
</template>

<script>
import api from '../helpers-api';
import UserForm from '../components/UserForm.vue';

export default {
  name: 'UserProfile',
  components: {
    UserForm,
  },
  data() {
    return {
      errors: {},
    };
  },
  methods: {
    /**
     * Change User database and update the $state
     */
    async updateProfile(payload, payloadFile = []) {
      this.errors = '';

      try {
        await api.post('/user/profile', payload);

        // If there is File input, upload it
        if (payloadFile.length > 0) {
          payloadFile.forEach(async (file) => {
            await this.$store.dispatch('uploadImage', file);
          });
        }

        this.errors = 'You have successfully updated your profile';
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data.message;
        } else {
          // server timeout
          this.errors = `${error.message}. Please try again later.`;
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/vars'

.user-form__wrapper
  --blogWidth: 720px
  --fieldGap: 0.5rem

  margin-bottom: var(--groupSpacing)

  @media ($below-s)
    --blogWidth: 360px
    --fieldGap: 0.25rem
</style>

<template>
  <table class="collections alignwide">
    <thead>
      <tr>
        <th class="column-content">
          Collection
        </th>
        <th class="column-summary">
          Summary
        </th>
        <th class="column-submission">
          Your Submission
        </th>
      </tr>
    </thead>
    <tbody>
      <CollectionRow
        v-for="(coll, index) in collections"
        :key="index"
        :collection="coll"
        :entry="entries[coll.ID]"
      />
      <tr v-if="collections.length === 0" class="collection-empty-message">
        <td colspan="4" style="padding: 1rem 0.5rem;">
          There is currently no collection
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import CollectionRow from './CollectionRow.vue';

export default {
  name: 'Collections',
  components: {
    CollectionRow,
  },
  props: {
    collections: {
      type: Array,
      required: true,
    },
    entries: {
      type: [Object, Array],
      required: true,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/vars"

.collections
  width: 100%
  margin-bottom: var(--groupSpacing)

  background-color: var(--textInvert)
  box-shadow: var(--shadow0)
  border-radius: var(--gRadius)

  .column-content
    width: 40%
  .column-summary
    width: 20%
  .column-submission
    width: 40%

thead
  border-bottom: 1px solid rgba(black, .1)

th
  padding: 0.25rem 0.5rem
  font-family: var(--hFontFamily)
  text-align: left
  text-transform: uppercase

@media ($below-s)
  .collections
    thead,
    tbody,
    tr,
    th,
      display: block

    th
      display: none
</style>

<template>
  <tr class="collection">
    <td class="collection__content">
      <img
        v-if="collection.thumbnail_src"
        :src="collection.thumbnail_src"
        class="collection__thumbnail"
      >
      <h6>{{ collection.post_title }}</h6>
      <span class="collection__subject">
        For: {{ collection.meta.subject }}
      </span>
      <!-- eslint-disable vue/no-v-html -->
      <article>
        <div v-html="isExpanded ? collection.post_content : contentTruncated" />
        <a
          v-if="contentTruncated !== collection.post_content"
          href="#"
          class="collection__toggle-content"
          @click.prevent="isExpanded = !isExpanded"
        >
          {{ isExpanded ? 'Read Less' : 'Read More' }}
        </a>
      </article>
      <!-- eslint-disable -->
    </td>
    <td class="collection__summary">
      <p>
        <strong>Collection Type</strong>:
        <span>{{ collection.collection_type }}</span>
      </p>
      <p v-if="collection.meta.loss_reason">
        <strong>Cause</strong>:
        <span>{{ collection.meta.loss_reason }}</span>
      </p>
      <p class="is-loss-date" v-if="collection.meta.loss_date">
        <strong>Happened at</strong>:
        <span>{{ collection.meta.loss_date }}</span>
      </p>
      <p v-if="collection.meta.payment_type">
        <strong>Payment Type</strong>:
        <span>{{ collection.meta.payment_type }}</span>
      </p>
      <p v-if="collection.meta.amount">
        <strong>Amount</strong>:
        <span>{{ collection.meta.amount }}</span>
      </p>

      <p class="is-open-date">
        <strong>Open</strong>:
        <span>{{ getLocalDate(collection.post_date_gmt) }}</span>
      </p>
      <p v-if="collection.meta.exp_date_gmt" class="is-close-date">
        <strong>Deadline</strong>:
        <span>{{ getLocalDate(collection.meta.exp_date_gmt) }}</span>
      </p>
      <p v-else class="is-close-date">
        <strong>Deadline</strong>:
        <span>Not specified</span>
      </p>
    </td>
    <td>
      <EntryForm
        :entry="entry"
        :content.sync="entry.comment_content"
        :edit-mode="isEditMode"
        :payment-type="collection.meta.payment_type"
        @submitted="submitted"
        @closed="isEditMode = false"
      />

      <div class="collection__actions">
        <small v-if="entry.comment_karma === '-1'" class="message-resubmit">
          Your submission is rejected. Please re-submit.
        </small>

        <p v-if="collection.post_status === 'private'" class="message-closed">
          This collection is already closed.
        </p>
        <p v-else-if="entry.comment_karma === '2'" class="message-approved">
          Thank you for your contribution.
        </p>
        <button
          v-else-if="!isEditMode"
          class="button"
          @click="toggleForm"
        >
          <span>Edit</span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import EntryForm from './EntryForm.vue';

export default {
  name: 'CollectionRow',
  components: {
    EntryForm,
  },
  props: {
    collection: {
      type: [Object, Array],
      required: true,
    },
    entry: {
      type: [Object, Array, null],
      default: () => ({
        comment_karma: '0',
        comment_content: null,
      }),
    },
  },
  data() {
    const isEmpty = this.$props.entry.comment_karma === '0';
    const isPrivate = this.$props.collection.post_status === 'private';

    return {
      isEditMode: isEmpty && !isPrivate,
      isExpanded: false,
    };
  },
  computed: {
    contentTruncated() {
      const contentArray = this.$props.collection.post_content.trim().split(' ');

      if (contentArray.length > 30) {
        return contentArray.slice(0, 30).join(' ');
      }

      return this.$props.collection.post_content;
    },
  },

  methods: {
    toggleForm() {
      this.isEditMode = !this.isEditMode;
    },

    /**
     * @emit 'submitted' from EntryForm
     */
    submitted() {
      this.isEditMode = false;
    },

    getLocalDate(dateGMT) {
      const date = moment(dateGMT, 'YYYY-MM-DD HH:mm:ss');
      return date.format('DD MMM YYYY HH:mm');
    },

    getDateFromNow(dateGMT) {
      const date = moment(dateGMT, 'YYYY-MM-DD HH:mm:ss');
      return date.fromNow();
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/vars"

.collection
  border-bottom: 1px solid rgba(black, .1)

.collection td
  padding: 0.5rem 0.5rem
  vertical-align: top
  font-size: var(--smallFontSize)

  @media ($below-s)
    display: block

.collection__content
  h6
    margin-bottom: 0.75rem

  div
    margin-top: 0.25rem

    /deep/ * + *
      margin-top: 0.75rem

.collection__toggle-content
  display: inline-block
  margin-top: 0.5rem
  padding: 0 0.25rem
  border: 1px solid currentColor
  border-radius: var(--gRadius)

  text-decoration: none
  text-transform: uppercase
  font-size: 12px
  font-weight: 600
  color: var(--textDim)
  letter-spacing: 0.05em

  &:focus,
  &:hover
    color: var(--color1)

.collection__thumbnail
  max-width: 40%
  float: right

.collection__icon
  display: flex
  align-items: center
  margin-top: 0.25rem
  font-style: normal
  font-size: var(--xsmallFontSize)

  svg
    width: 1rem
    height: 1rem
    margin-right: 0.5rem
    vertical-align: middle

  path
    fill: var(--color1)

td.collection__summary
  font-size: 12px

  > *
    display: block
  > * + *
    margin-top: 0.25rem

  span
    display: inline-block

  // span
  //   display: inline-block
  //   padding: 0.25rem
  //   margin-top: 0.25rem
  //   background-color: var(--color1Light)

  //   line-height: 1.25
  //   box-shadow: var(--shadow0)
  //   border-radius: var(--gRadius)

.button
  display: inline-block
  letter-spacing: 0.05em

.collection__actions
  margin-top: 0.5rem

  small
    display: block
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    line-height: 1.2

</style>

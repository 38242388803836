<template>
  <main role="main">
    <h3 class="alignwide">
      All Posts
    </h3>
    <Posts :posts="featuredPosts" />
    <Posts :posts="otherPosts" />
  </main>
</template>

<script>
import Posts from '../components/Posts.vue';

export default {
  name: 'PostArchive',
  components: {
    Posts,
  },
  data() {
    return {
      featuredPosts: [],
      otherPosts: [],
    };
  },

  async created() {
    this.featuredPosts = await this.$store.dispatch('getFeaturedPosts');
    this.otherPosts = await this.$store.dispatch('getOtherPosts');
  },

  methods: {},
};
</script>

<style lang="sass" scoped>
</style>

<template>
  <div id="login">
    <figure class="login-logo">
      <router-link :to="{name: 'UserLogin'}" title="Back to Login">
        <img src="@/images/logo.png">
      </router-link>
    </figure>

    <router-view />

    <!-- eslint-disable vue/no-v-html -->
    <footer
      v-if="loginFooter"
      class="login-footer"
      v-html="loginFooter"
    />
    <!--eslint-enable-->
  </div>
</template>

<script>
import api from '../helpers-api';

export default {
  name: 'LayoutLogin',
  components: {},

  data() {
    return {
      loginFooter: '',
    };
  },

  async created() {
    this.loginFooter = this.$store.state.loginFooter;

    // if empty, do database query to get it
    if (!this.loginFooter) {
      const response = await api.get('/user/login-footer');
      this.loginFooter = response.data;
      this.$store.commit('setLoginFooter', this.loginFooter);
    }
  },

  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/vars"

#login
  --blogWidth: 320px

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background: var(--color1Light) url('../images/login-background.jpg') center center no-repeat
  background-size: cover
  background-attachment: fixed

  padding: 2rem 0 5rem
  min-height: 100vh

  @media ($below-xs)
    padding-bottom: 0

.login-logo
  display: inline-block
  margin-bottom: 1rem
  text-align: center

  img
    max-height: 80px

.login-footer
  position: absolute
  left: 0
  bottom: 0
  right: 0
  width: 100%
  padding: 0.5rem

  background-color: rgba(black, .75)
  color: white
  font-size: var(--xsmallFontSize)

  p
    max-width: var(--siteWidth)
    margin-right: auto
    margin-left: auto

  @media ($below-xs)
    position: static
    margin-top: auto
</style>

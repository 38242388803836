<template>
  <header class="header header-mobile">
    <div class="header__inner">
      <router-link class="logo" :to="{name: 'Home'}">
        <img :src="require('../images/logo.png')" alt="">
      </router-link>

      <a
        class="header__toggle"
        href="#menu"
        @click.prevent="toggle"
      >
        <span>Menu</span>
        <CloseSVG v-if="isOpen" />
        <MenuSVG v-else />
      </a>
    </div>

    <transition name="offcanvas">
      <aside v-show="isOpen" class="offcanvas">
        <div class="offcanvas__inner">
          <HeaderMenu />
        </div>
      </aside>
    </transition>
  </header>
</template>

<script>
import HeaderMenu from './HeaderMenu.vue';
import MenuSVG from '../svg/menu.svg';
import CloseSVG from '../svg/close.svg';

export default {
  name: 'HeaderMobile',
  components: {
    HeaderMenu,
    CloseSVG,
    MenuSVG,
  },
  data() {
    return {
      isOpen: false,
    };
  },

  watch: {
    $route() {
      this.isOpen = false;
    },
  },

  methods: {
    /**
     * Open the hidden menu
     */
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/vars';

.header
  --headerHeight: 57px
  padding: 0.5rem
  margin-bottom: var(--groupSpacing)
  background-color: var(--text)

.logo
  img
    max-height: 42px

.header__inner
  display: flex
  align-items: center
  width: 100%

.header__toggle
  display: flex
  align-items: center
  justify-content: center
  margin-left: auto
  padding: 0 0.5rem

  color: var(--textInvert)
  border-radius: var(--gRadius)

  &:hover
    background-color: rgba(white, .1)

  svg
    margin-left: 0.5rem
    width: 1rem
  path
    fill: var(--textInvert)
  span
    line-height: 1
    text-transform: uppercase
    font-weight: 700

.offcanvas
  cursor: default
  position: fixed
  z-index: 100
  top: var(--headerHeight)
  bottom: 0
  right: 0

  background: var(--text)
  width: 420px
  max-width: 420px

  color: var(--textInvert)
  font-size: var(--mediumFontSize)
  box-shadow: var(--shadow3)

  //
  @media ($below-xs)
    width: 320px
    max-width: 320px

// before enter
.offcanvas-enter
  visibility: hidden
  opacity: 0
  transform: translateX(100%)

// when enter animation is running
.offcanvas-enter-active
  transition: var(--gTransition)

// enter animation finish line
.offcanvas-enter-to
  visibility: visible
  opacity: 1
  transform: none

// when leaving animation is running
.offcanvas-leave-active
  transition: none

// leaving animation finish line
.offcanvas-leave-to
  transform: translateX(100%)

.offcanvas__inner
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start

  overflow-y: auto
  height: 100%
  padding: 2.5rem 0
  list-style-type: none

  -webkit-overflow-scrolling: touch

</style>

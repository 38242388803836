<template>
  <div class="collection-form__wrapper">
    <h1>Create Collection</h1>
    <CollectionForm submit-text="Create Collection" @submit="onSubmit" />
  </div>
</template>

<script>
import CollectionForm from '../components/CollectionForm.vue';

export default {
  name: 'CollectionCreate',
  components: {
    CollectionForm,
  },
  data() {
    return {};
  },
  methods: {
    async onSubmit(payload) {
      const collectionID = await this.$store.dispatch('createCollection', payload);
      this.$router.push({ name: 'CollectionSingle', params: { id: collectionID } });
    },
  },
};
</script>

<style lang="sass" scoped>
.collection-form__wrapper
  max-width: var(--siteWidth)
  margin: var(--blockSpacing) auto

</style>

<template>
  <main class="page-404">
    <h1>404</h1>
    <h2>Page Not Found</h2>
    <p>
      The page you are looking for has been deleted or does not exist.
    </p>
  </main>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="sass">
.page-404
  text-align: center
</style>

<template>
  <ul class="menu">
    <li class="menu-item">
      <router-link :to="{name: 'Home'}">
        Home
      </router-link>
    </li>
    <li class="menu-item">
      <router-link :to="{name: 'PostArchive'}">
        News
      </router-link>
    </li>
    <li class="menu-item">
      <router-link :to="{name: 'CollectionArchive'}">
        Collections
      </router-link>
    </li>
    <li class="menu-item menu-item-has-children">
      <a>Info</a>
      <ul class="sub-menu">
        <li class="sub-menu-item">
          <router-link :to="{name: 'Page', params: { slug: 'about-us' }}">
            About Us
          </router-link>
        </li>
        <li class="sub-menu-item">
          <router-link :to="{name: 'Page', params: { slug: 'faq' }}">
            FAQ
          </router-link>
        </li>
        <li class="sub-menu-item">
          <router-link :to="{name: 'Page', params: { slug: 'contact' }}">
            Contact
          </router-link>
        </li>
      </ul>
    </li>
    <li v-if="$store.state.isAdmin" class="menu-item menu-item-has-children">
      <a href="#">Admin</a>

      <ul class="sub-menu">
        <li class="sub-menu-item">
          <router-link :to="{name: 'CollectionArchive'}">
            View All
          </router-link>
        </li>
        <li class="sub-menu-item">
          <router-link :to="{name: 'CollectionCreate'}">
            Create New
          </router-link>
        </li>
      </ul>
    </li>
    <li v-if="$store.state.isLoggedIn" class="menu-item menu-item-has-children">
      <a href="#">My Account</a>

      <ul class="sub-menu">
        <li class="sub-menu-item">
          <router-link :to="{name: 'UserProfile'}">
            Change Profile
          </router-link>
        </li>
        <li class="sub-menu-item">
          <a href="#logout" @click.prevent="logout">
            Logout
          </a>
        </li>
      </ul>
    </li>
    <li v-else class="menu-item">
      <router-link :to="{name: 'UserLogin'}">
        Login
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {},
  methods: {
    logout() {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('displayName');

      this.$store.commit('logoutUser');
      this.$router.push({ name: 'UserLogin' });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/vars'

.menu
  --navPadding: 1.5rem 0.75rem

  display: flex
  flex-wrap: wrap
  margin-left: auto
  list-style-type: none
  padding: 0
  color: var(--textInvert)
  transition: var(--gTransition)

  .offcanvas &
    --navPadding: 1rem 1.25rem
    display: block
    margin-left: 0
    width: 100%

.menu-item
  position: relative

  .footer &
    --navPadding: 0.5rem
    width: 50%

.menu-item > a
  display: flex
  align-items: center
  height: 100%
  padding: var(--navPadding, 0.5rem)

  line-height: 1
  font-size: var(--navFontSize, inherit)
  font-weight: var(--hFontWeight)
  color: inherit

  .footer &
    letter-spacing: 0.025em
    text-transform: uppercase
    background-color: transparent

  .offcanvas &
    letter-spacing: 0.025em

  &::before
    content: ""
    display: block
    visibility: hidden
    opacity: 0

    position: absolute
    bottom: 0
    left: 10px
    right: 10px
    height: 4px
    background-color: var(--color1)

    transition: var(--gTransition)

    .footer &
      display: none
    .offcanvas &
      top: 0
      left: 0
      right: auto
      bottom: auto
      height: 100%
      width: 6px

.menu-item > a:hover
  background-color: rgba(white, .1)

  .footer &
    background-color: rgba(white, .2)
    text-decoration: underline

  .offcanvas &
    background-color: rgba(white, .1)
    opacity: .75

// active state
.menu-item .router-link-exact-active
  &::before
    visibility: visible
    opacity: 1

// Add down arrow if has children
.menu-item-has-children > a
  &::after
    content: ""
    display: block
    margin-left: 0.5rem
    width: 0.75rem
    height: 0.75rem
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E") no-repeat center center
    background-size: 100% auto

    @media ($below-nav)
      display: none

    .footer &
      display: none

///// CHILDREN MENU

.sub-menu
  --dropdownColumns: 1

  position: absolute
  z-index: 10
  top: calc(100% + 4px)
  left: 50%
  visibility: hidden
  opacity: 0

  min-width: calc(180px * var(--dropdownColumns))
  max-width: calc(220px * var(--dropdownColumns))
  padding: 0.75em 0.5em
  border-radius: var(--gRadius)
  background-color: var(--text)

  text-align: left
  list-style-type: none

  transition: var(--gTransition)
  transform: translateX(-50%)
  box-shadow: var(--shadow2)

  .footer &
    display: none

  .offcanvas &
    visibility: visible
    opacity: 1
    position: static
    overflow: hidden
    width: 100%
    max-width: none
    padding: 0
    padding-left: 2rem
    background-color: transparent

    list-style-type: none

    box-shadow: none
    transform: none
    transition: var(--gTransition)

  .menu-item:hover &
    visibility: visible
    opacity: 1

  // need to be separated with ":hover" for browser support
  .menu-item:focus-within &
    visibility: visible
    opacity: 1

.sub-menu-item
  display: block
  color: var(--textInvert)

  a
    display: block
    padding: 0.375rem 0.75rem
    border-radius: var(--gRadius)

  a:hover
    background-color: var(--color1)

    .offcanvas &
      background-color: rgba(white, .1)
      opacity: .75
</style>

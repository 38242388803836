import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import api from './helpers-api';

Vue.use(Vuex);

export const mutations = {
  loginUser(state, { token, email, displayName }) {
    state.isLoggedIn = true;
    state.userToken = token;
    state.userEmail = email;
    state.userDisplayName = displayName;
  },

  logoutUser(state) {
    state.isLoggedIn = false;
    state.userToken = '';
    state.userEmail = '';
    state.userDisplayName = '';

    state.collections = [];
    state.entries = {};
    state.closedCollections = [];
    state.closedEntries = {};
    state.isAdmin = false;
  },

  /**
   * Set the message at the bottom of Login, Register, and Forgot password page
   */
  setLoginFooter(state, loginFooter) {
    state.loginFooter = loginFooter;
  },

  setPosts(state, payload) {
    state.posts = payload;
  },

  setOtherPosts(state, payload) {
    state.otherPosts = payload;
  },

  cachePages(state, payload) {
    if (!state.pages) {
      state.pages = [];
    }

    state.pages.push(payload);
  },

  setActiveCollections(state, { collections, entries }) {
    state.collections = collections;
    state.entries = entries;
  },

  setClosedCollections(state, { collections, entries }) {
    state.closedCollections = collections;
    state.closedEntries = entries;
  },

  /**
   * Update the entry data in state
   */
  updateEntryCache(state, { collectionID, content, karma }) {
    state.entries[collectionID].comment_content = content;
    state.entries[collectionID].comment_karma = karma;
  },

  // updateCollection(state, { id, collection }) {
  //   state.collections[id] = collection;
  // },

  // setConstant(state, payload) {
  //   state.isLoggedIn = payload.isLoggedIn;
  // },

  /**
   * Update stored profile. payload needs to have `key` and `value`.
   */
  // updateProfile(state, payload) {
  //   state[payload.key] = payload.value;
  // },
};

export const actions = {
  /**
   * Get latest 3 posts
   */
  async getFeaturedPosts({ commit, state }) {
    if (state.posts.length === 0) {
      const response = await api.get('/posts/home');
      commit('setPosts', response.data);
    }

    return state.posts;
  },

  /**
   * Get all other posts aside from latest 3
   */
  async getOtherPosts({ commit, state }) {
    if (state.otherPosts.length === 0) {
      const response = await api.get('/posts/other');
      commit('setOtherPosts', response.data);
    }

    return state.otherPosts;
  },

  /**
   * Get the data for 1 post, taking from stored state
   */
  async getSinglePost({ state, dispatch }, slug) {
    let post = state.posts.find((p) => p.post_name === slug);

    if (!post) {
      await dispatch('getOtherPosts'); // in case not loaded yet
      post = state.otherPosts.find((p) => p.post_name === slug);
    }

    return post;
  },

  /**
   * Get static page date
   */
  async getPage({ commit, state }, slug) {
    let page;

    if (state.pages) {
      page = state.pages.find((p) => p.post_name === slug);
    }

    if (!page) {
      const response = await api.get(`/pages/${slug}`);
      page = response.data;
      commit('cachePages', page);
    }

    return page;
  },

  /**
   * Upload image
   */
  async uploadImage(context, { file, userID, metaKey }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', userID);
    formData.append('meta_key', metaKey);

    return api.post(
      '/user/upload-image',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },

  /**
   * Check if user if logged in
   */
  async checkLoginState({ commit }) {
    const token = localStorage.getItem('token');

    // if no token, empty the loggedIn cache
    if (!token) {
      commit('logoutUser');
      return false;
    }

    // if has token, check if it's still valid
    try {
      await axios.post(
        `${process.env.VUE_APP_AUTH_URL}/token/validate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      commit('loginUser', {
        token,
        email: localStorage.getItem('email'),
        displayName: localStorage.getItem('displayName'),
      });

      return true;
    } catch (error) {
      // if token invalid, it will go here
      localStorage.setItem('token', '');

      return false;
    }
  },

  /**
   * @warn - no test for test
   */
  async getCollections({ commit, state }) {
    if (state.collections.length === 0) {
      const response = await api.get('/collections/active');
      commit('setActiveCollections', {
        collections: response.data.collections,
        entries: response.data.entries,
      });
    }

    return {
      collections: state.collections,
      entries: state.entries,
    };
  },

  /**
   * @warn - no test for this
   */
  async getClosedCollections({ commit, state }) {
    if (state.closedCollections.length === 0) {
      const response = await api.get('/collections/closed');
      commit('setClosedCollections', {
        collections: response.data.collections,
        entries: response.data.entries,
      });
    }

    return {
      collections: state.closedCollections,
      entries: state.closedEntries,
    };
  },

  /**
   * Edit cheque data to database
   */
  async updateEntry(context, { commentID, commentContent, commentPhoto }) {
    const formData = new FormData();
    formData.append('comment_ID', commentID);
    formData.append('comment_content', commentContent);
    formData.append('comment_photo', commentPhoto);

    const isUpdated = await api.post(
      '/entry/update',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return isUpdated;
  },

  /**
   * Create new collection and return it's ID
   */
  // async createCollection(context, payload) {
  //   const collection = await api.post('/collections', payload);
  //   return collection;
  // },

  /**
   * Edit the collection
   */
  // async editCollection(context, payload) {
  //   const collection = await api.put(`/collections/${payload.id}`, payload);
  //   return collection;
  // },

  /**
   * Add an accept or reject history log as reply to the comment
   */
  // async createReply(context, payload) {
  //   const reply = await api.post(`/entry/${payload.id}/reply`, payload);
  //   return reply;
  // },

  /**
   * Send the comment into trash
   */
  // async trashEntry(context, payload) {
  //   const entryID = await api.post(`/entry/${payload.id}/trash`, payload);
  //   return entryID;
  // },

  /**
   * Permanently delete the comment
   */
  // async deleteEntry(context, payload) {
  //   const entryID = await api.post(`/entry/${payload.id}/delete`, payload);
  //   return entryID;
  // },

  /**
   * Add a strike flag to the comment
   */
  // async strikeEntry(context, payload) {
  //   const entryID = await api.post(`/entry/${payload.id}/strike`, payload);
  //   return entryID;
  // },
};

const store = {
  // This is global data, use mutations and actions to change this value.
  state: {
    posts: [], // for home page
    otherPosts: [], // additional for archive page

    collections: [],
    entries: {}, // key-value pair, with key being the collection ID

    closedCollections: [],
    closedEntries: {},

    isAdmin: false,

    isLoggedIn: localStorage.getItem('isLoggedIn') || false,
    userToken: localStorage.getItem('token') || '',
    userEmail: localStorage.getItem('email') || '',
    userDisplayName: localStorage.getItem('displayName') || '',
    loginFooter: '',
  },

  mutations,
  actions,
  modules: {
  },
};

export default new Vuex.Store(store);

<template>
  <div class="modal" @click.self="close">
    <div class="modal__inner">
      <p v-if="message">
        {{ message }}
      </p>
      <img v-if="image" :src="image">
      <a href="#close" @click.prevent="close">
        <CloseSVG />
      </a>
    </div>
  </div>
</template>

<script>
import CloseSVG from '../svg/close.svg';

export default {
  name: 'Modal',
  components: {
    CloseSVG,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('closed');
    },
  },
};
</script>

<style lang="sass" scoped>
.modal
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  z-index: 100
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(black, .5)

@keyframes fadeIn
  from
    opacity: 0.5
    transform: scale(0.9)
  to
    opacity: 1
    transform: scale(1)

.modal__inner
  cursor: initial
  display: flex
  align-items: initial
  position: relative
  max-width: 560px
  max-height: 85vh
  box-shadow: 0 0 4px 6px rgba(black, .1)

  padding: 1rem
  background-color: white
  text-align: center

  animation: 0.3s fadeIn both ease-in-out

  * + *
    margin-top: 1rem

  img
    object-fit: contain
    object-position: center center
    width: auto
    height: auto

[href="#close"]
  position: absolute
  top: 0
  right: 0
  margin: 0
  opacity: .5
  transform: translateY(0) translateX(125%)

  &:hover
    opacity: .25

  svg
    width: 1.75rem
    height: 1.75rem
</style>

<template>
  <div class="page">
    <div v-if="page" class="page__body">
      <header class="page__title">
        <h2>{{ page.post_title }}</h2>
      </header>

      <!-- eslint-disable vue/no-v-html -->
      <main v-html="page.post_content" />
      <!-- eslint-disable -->
    </div>
    <div v-else class="page__body is-not-found">
      <main>
        <h2>404 Not Found</h2>
        <p>
          The page you are looking for does not exist or have been deleted.
        </p>
      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Page',
  components: {},
  data() {
    return {
      page: {},
    };
  },
  watch: {
    $route() {
      this.getPage();
    },
  },

  created() {
    this.getPage();
  },

  mounted() {
    document.addEventListener('submit', this.cf7Submit);
  },

  beforeDestroy() {
    document.removeEventListener('submit', this.cf7Submit);
  },

  methods: {
    async getPage() {
      this.page = await this.$store.dispatch('getPage', this.$route.params.slug);
      document.title = this.page.post_title;
    },

    async cf7Submit(e) {
      e.preventDefault();
      // abort if form is not cf7
      if (!e.target.classList.contains('wpcf7-form')) {
        return;
      }

      const $form = e.target;
      $form.classList.add('is-loading');
      const formData = new FormData($form);

      const formID = formData.get('_wpcf7');
      const postURL = `${process.env.VUE_APP_DOMAIN}/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`;

      const result = await axios.post(postURL, formData);

      $form.classList.remove('is-loading');

      switch (result.data.status) {
        case 'validation_failed':
          alert(result.data.message);
          break;

        case 'mail_sent':
          alert(result.data.message);
          $form.reset();
          break;

        default:
          console.log(result);
      }
    },
  },
};
</script>

<style lang="sass">
@import "../sass/vars"

.page
  display: flex
  max-width: var(--siteWidth)
  margin: 0 auto var(--blockSpacing)

  @media ($below-s)
    flex-wrap: wrap

.page__body
  flex: 1
  width: var(--blogWidth)
  margin-bottom: 2rem
  padding: 1rem
  border-radius: var(--gRadius)
  background-color: white
  box-shadow: var(--shadow1)

  @media ($below-s)
    width: calc(100% - 20px)
    padding: 1rem 0

.page__title
  margin-right: auto
  margin-left: auto
  margin-bottom: var(--blockSpacing)
  padding-bottom: var(--blockSpacing)
  border-bottom: 1px solid
  max-width: var(--blogWidth)

  @media ($below-m)
    padding-right: var(--gridRim)
    padding-left: var(--gridRim)

// FAQ Shortcode
.faq
  border: 2px solid rgba(black, .2)
  border-radius: var(--gRadius)

.faq__question
  position: relative
  cursor: pointer
  padding: 0.75rem 1rem 0.75rem 2rem
  margin: -1px
  background-color: rgba(black, .1)

  font-size: var(--h6Size)
  line-height: 1.25
  transition: var(--gTransition)

  &:hover
    opacity: .8
  &:active
    transition: none
    box-shadow: var(--shadow0)
    transform: translateY(1px)

  &::-webkit-details-marker
    display: none
  &::marker
    display: none
    font-size: 0

  &::before
    content: "+"
    position: absolute
    left: 0.75rem
    display: inline-block
    margin-right: 0.75rem
    font-family: monospace

    [open] &
      content: "-"
  [open] &
    opacity: 1

.faq__answer
  padding: 1rem 1.5rem

  @media ($below-s)
    padding: 0 0.75rem

  p + p
    margin-top: 1rem

// Contact Form

.wpcf7
  br
    display: none
  label
    display: inline-block
    margin-bottom: 0.25rem
    font-weight: 700
    font-size: smaller
    text-transform: uppercase
  p + p
    margin-top: var(--blockSpacing)

.wpcf7-form.is-loading
  position: relative

  &::before
    content: ""
    position: absolute
    z-index: 2
    top: 0
    right: 0
    left: 0
    bottom: 0
    background-color: rgba(black, .2)

  &::after
    content: ''
    position: absolute
    z-index: 2
    top: 50%
    left: 50%
    margin-top: -30px
    margin-left: -30px
    width: 50px
    height: 50px
    border-radius: 50px
    border: 5px solid var(--textInvert)
    border-top-color: var(--color1)
    animation: loading 2s linear infinite

@keyframes loading
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>

<template>
  <div class="single-post">
    <div v-if="post" class="single-post__body">
      <figure
        v-if="post.image"
        class="single-post__image"
        data-image-fit="2:1"
      >
        <img :src="post.image" :alt="post.post_title + ' Thumbnail'">
      </figure>

      <header class="single-post__title">
        <h1>{{ post.post_title }}</h1>
        <time>
          {{ post.post_date }}
        </time>
      </header>

      <!-- eslint-disable vue/no-v-html -->
      <main v-html="post.post_content" />
      <!-- eslint-disable -->
    </div>
    <div v-else class="single-post__body is-not-found">
      <main>
        <h2>404 Not Found</h2>
        <p>
          The news you are looking for does not exist or have been deleted.
        </p>
      </main>
    </div>

    <aside class="single-post__sidebar">
      <h4>Other News</h4>
      <Posts :posts="relatedPosts" extra-classes="" />
    </aside>
  </div>
</template>

<script>
import Posts from '../components/Posts.vue';

export default {
  name: 'PostSingle',
  components: {
    Posts,
  },
  data() {
    return {
      post: {},
      relatedPosts: [],
    };
  },
  watch: {
    $route() {
      this.getPost();
    },
  },

  /**
   * Get item using the ID in URL and Related Items
   */
  created() {
    this.getPost();
  },

  methods: {
    async getPost() {
      this.relatedPosts = await this.$store.dispatch('getFeaturedPosts');
      this.post = await this.$store.dispatch('getSinglePost', this.$route.params.slug);

      document.title = this.post.post_title;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/vars"

.single-post
  display: flex
  max-width: var(--siteWidth)
  margin: 0 auto var(--blockSpacing)

  @media ($below-s)
    flex-wrap: wrap

.single-post__body
  flex: 1

.single-post__sidebar
  width: 30%

  @media ($above-s)
    .post-list
      --postColumns: 1

  @media ($below-s)
    width: 100%
    padding-left: var(--gridRim)
    padding-right: var(--gridRim)

.single-post__image
  max-width: var(--blogWidth)
  margin: 0 auto

.single-post__title
  margin-right: auto
  margin-left: auto
  margin-bottom: var(--blockSpacing)
  padding-bottom: var(--blockSpacing)
  border-bottom: 1px solid
  max-width: var(--blogWidth)

  @media ($below-m)
    padding-right: var(--gridRim)
    padding-left: var(--gridRim)

.single-post__sidebar
  .post-list
    position: sticky
    top: 1rem

  h4
    margin-bottom: var(--blockSpacing)

  @media ($above-s) and ($below-l)
    padding-right: var(--gridRim)
</style>

<template>
  <div class="collection-form__wrapper">
    <h1>Edit Collection</h1>
    <CollectionForm
      v-if="collection"
      :collection="collection"
      submit-text="Edit Collection"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import CollectionForm from '../components/CollectionForm.vue';

export default {
  name: 'CollectionEdit',
  components: {
    CollectionForm,
  },
  data() {
    return {
      collection: null,
    };
  },

  /**
   * Get the collection data to pre-populate the field
   */
  async mounted() {
    this.collection = await this.$store.dispatch('getCollection', this.$route.params.id);
  },

  methods: {
    /**
     * After submitted
     */
    async onSubmit(payload) {
      await this.$store.dispatch('updateCollection', payload);
      this.$router.push({ name: 'CollectionSingle', params: { id: payload.id } });
    },
  },
};
</script>

<style lang="sass" scoped>
.collection-form__wrapper
  max-width: var(--siteWidth)
  margin: var(--blockSpacing) auto

</style>

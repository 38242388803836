<template>
  <div id="app">
    <Header v-show="!isMobile" />
    <HeaderMobile v-show="isMobile" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from './Header.vue';
import HeaderMobile from './HeaderMobile.vue';
import Footer from './Footer.vue';

export default {
  name: 'Layout',
  components: {
    Header,
    HeaderMobile,
    Footer,
  },

  data() {
    return {
      isMobile: false,
      windowWidth: 0,
    };
  },

  watch: {
    $route() {
      this.checkRoute();
    },
  },

  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();

    // Check current route
    this.checkRoute();
  },

  methods: {
    /**
     * Update window size so it can show the correct Header
     */
    checkScreen() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    /**
     * Check current route and do something with it
     */
    checkRoute() {
      // console.log( this.$route.name );
    },
  },
};
</script>

<style lang="sass" scoped>
#app
  display: flex
  flex-direction: column
  min-height: 100vh
</style>

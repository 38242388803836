<template>
  <main role="main">
    <div class="columns alignwide">
      <div class="column">
        <h2>Open Collections</h2>
      </div>
      <div class="column is-vertically-aligned-center">
        <div class="buttons is-horizontally-aligned-right">
          <router-link :to="{name: 'CollectionArchive'}" class="button is-style-outline">
            View All Collections
          </router-link>
        </div>
      </div>
    </div>

    <Collections
      :collections="activeCollections"
      :entries="activeEntries"
    />

    <hr class="separator is-style-dots">

    <div class="columns alignwide">
      <div class="column">
        <h2>Latest News</h2>
      </div>
      <div class="column is-vertically-aligned-center">
        <div class="buttons is-horizontally-aligned-right">
          <router-link :to="{name: 'PostArchive'}" class="button is-style-outline">
            View All News
          </router-link>
        </div>
      </div>
    </div>

    <Posts :posts="featuredPosts" />
  </main>
</template>

<script>
import Posts from '../components/Posts.vue';
import Collections from '../components/Collections.vue';

export default {
  name: 'Home',
  components: {
    Posts,
    Collections,
  },
  data() {
    return {
      featuredPosts: [],
      activeCollections: [],
      activeEntries: {},
    };
  },
  async created() {
    this.featuredPosts = await this.$store.dispatch('getFeaturedPosts');

    const { collections, entries } = await this.$store.dispatch('getCollections');
    this.activeCollections = collections;
    this.activeEntries = entries;
  },
};
</script>

<style lang="sass" scoped>
.post-list
  margin-bottom: var(--groupSpacing)
</style>

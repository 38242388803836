<template>
  <form class="user-form" @submit.prevent="submit">
    <label
      v-for="field in fields"
      :key="field.slug"
      :class="getFieldClasses(field)"
    >
      <h3 v-if="field.type === 'heading'">
        {{ field.label }}
      </h3>
      <span v-else class="field__label">
        {{ field.label }}
        <b v-if="field.is_required">
          *
        </b>
      </span>

      <textarea
        v-if="field.type === 'textarea'"
        v-model.trim="field.value"
        :name="field.slug"
        :required="field.is_required"
        :placeholder="field.placeholder"
        :disabled="!field.can_edit"
        @change="backupData"
      />
      <input
        v-else-if="field.type === 'file'"
        type="file"
        :name="field.slug"
        :required="field.is_required"
        :accept="field.allow_extensions"
        :multiple="field.allow_multiple"
        @change="parseFile"
      >
      <date-picker
        v-else-if="field.slug === 'wpum_birthday'"
        v-model.trim="field.value"
        type="date"
        format="DD/MM/YYYY"
        value-type="YYYY/MM/DD"
        :name="field.slug"
        :required="field.is_required"
        :placeholder="field.placeholder"
        :disabled="!field.can_edit"
        @change="backupData"
      />
      <date-picker
        v-else-if="field.slug === 'wpum_checkout_date'"
        v-model.trim="field.value"
        type="date"
        format="DD/MM/YYYY"
        value-type="format"
        :name="field.slug"
        :required="field.is_required"
        :placeholder="field.placeholder"
        :disabled="!field.can_edit"
        @change="backupData"
      />
      <input
        v-else-if="field.type !== 'heading'"
        v-model.trim="field.value"
        :type="field.type"
        :name="field.slug"
        :required="field.is_required"
        :placeholder="field.placeholder"
        :disabled="!field.can_edit"
        @change="backupData"
      >

      <p v-if="field.has_error" class="field__error">
        {{ field.has_error }}
      </p>
      <p v-if="field.description" class="field__description">
        {{ field.description }}
      </p>
    </label>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-show="message"
      :class="`user-form__message is-${messageType}`"
      v-html="message"
    />
    <!-- eslint-disable -->

    <div class="user-form__submit">
      <button type="submit">
        {{ $props.submitButton }}
      </button>
    </div>

    <Loading v-if="isLoading" />
  </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import api from '../helpers-api';
import Loading from './Loading.vue';

export default {
  name: 'UserForm',
  components: {
    DatePicker,
    Loading,
  },
  props: {
    submitButton: {
      type: String,
      default: 'Submit',
    },
    formCache: {
      type: String,
      default: 'form',
    },
    formEndpoint: {
      type: String,
      default: '/user/register-form',
    },
    errors: {
      type: [Object, String],
      required: true,
    },
  },

  data() {
    return {
      fields: [],

      imageFile: null,
      imageURL: '',
      imageName: '',

      message: '',
      messageType: 'error',
      isLoading: false,
    };
  },

  watch: {
    /**
     * Watch for error messages
     */
    errors(newVal) {
      this.isLoading = false;
      this.messageType = 'error';

      // error is in the form of { field_name: 'error message' }
      if (typeof newVal === 'object') {
        Object.keys(newVal).forEach((key) => {
          const targetIndex = this.fields.findIndex((field) => field.slug === key);
          this.fields[targetIndex].has_error = newVal[key];

          this.message = 'One or more fields has error, please check the highlighted one above.';
        });
      // if error is plain text
      } else if (typeof newVal === 'string') {
        this.message = newVal;
      }
    },
  },

  /**
   * Get fields data
   */
  async created() {
    const isProfileForm = this.$route.name === 'UserProfile';

    let fields = sessionStorage.getItem(this.$props.formCache);

    if (fields && !isProfileForm) {
      fields = JSON.parse(fields);

      // clean up the error message
      fields.map((field) => {
        field.has_error = '';
        return field;
      });
    } else {
      // get the form fields from API
      const response = await api.get(this.$props.formEndpoint);

      fields = response.data;
      sessionStorage.setItem(this.$props.formCache, JSON.stringify(fields));
    }

    this.fields = fields;
  },

  methods: {
    /**
     * Submit the form
     */
    async submit() {
      this.isLoading = true;
      this.message = '';

      // clean up the error message
      this.fields.map((field) => {
        field.has_error = '';
        return field;
      });

      const payload = this.getPayload([...this.fields]);
      const payloadFile = this.getPayloadFile([...this.fields]);

      this.messageType = 'success';
      this.$emit('submitted', payload, payloadFile);
    },

    /**
     * Form the classes for field
     */
    getFieldClasses(field) {
      const classes = ['field'];

      if (field.slug) {
        classes.push(`field--${field.slug}`);
      }

      if (field.has_error) {
        classes.push('has-error');
      }

      return classes.join(' ');
    },

    /**
     * Everytime an input is changed, we update the session cache
     */
    backupData() {
      sessionStorage.setItem(this.$props.formCache, JSON.stringify(this.fields));
    },

    /**
     * Get the File object and add it to the $fields
     */
    parseFile(e) {
      const file = e.currentTarget.files[0];
      const slug = e.currentTarget.getAttribute('name');
      const targetIndex = this.fields.findIndex((field) => field.slug === slug);

      this.fields[targetIndex].value = file;
    },

    /**
     * Format the form data before being passed into API
     * @param Object fields - The form fields object from Vue data
     * @return Object - Key-value pair of field's name and its value
     */
    getPayload(fields) {
      // remove the field heading and file
      const filteredFields = fields.filter((field) => {
        if (field.slug.includes('wpum_label')) {
          return false;
        }

        // File upload is handled separately
        if (field.type === 'file') {
          return false;
        }

        return true;
      });

      // create payload for POST data
      return filteredFields.reduce((result, field) => {
        if (field.value) {
          result[field.slug] = field.value;
        }

        return result;
      }, {});
    },

    /**
     *
     */
    getPayloadFile(fields) {
      return fields.reduce((result, field) => {
        // skip if not File field
        if (field.type !== 'file') {
          return result;
        }

        result.push({
          file: field.value,
          metaKey: field.slug,
        });

        return result;
      }, []);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/vars'

.user-form
  flex-direction: row
  flex-wrap: wrap
  column-gap: 0
  margin-right: calc(-1 * var(--fieldGap))
  margin-left: calc(-1 * var(--fieldGap))

  h3
    padding: 0.25rem 0.5rem
    background-color: var(--color1Light)
    font-size: var(--h4Size)
    color: var(--color1)

.field
  padding: 0 var(--fieldGap)
  width: 50%

  &[class*="wpum_label"]
    margin-top: 1rem
    width: 100%

  &.has-error
    input,
    textarea
      border-color: red

  @media ($below-xs)
    width: 100%

@media ($above-s)
  .field--first_name
    width: 60%

  .field--wpum_birthday
    width: 40%

  .field--wpum_staff_id,
  .field--wpum_work_email,
  .field--wpum_license_no
    width: 33.333%

.field--username
  display: none

.field__label
  b
    color: red

.user-form__submit
  padding-right: var(--fieldGap)
  padding-left: var(--fieldGap)

.user-form__message
  width: 100%
  padding-right: var(--fieldGap)
  padding-left: var(--fieldGap)

.field__description,
.field__error
  margin-top: 0.25rem
  color: var(--textDim)
  font-size: var(--smallFontSize)

.field__error
  color: red
</style>

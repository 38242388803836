<template>
  <main role="main">
    <h3 class="alignwide">
      Open Collections
    </h3>
    <Collections
      :collections="activeCollections"
      :entries="activeEntries"
    />

    <h3 class="alignwide">
      Closed Collections
    </h3>
    <Collections
      :collections="closedCollections"
      :entries="closedEntries"
    />
  </main>
</template>

<script>
import Collections from '../components/Collections.vue';

export default {
  name: 'CollectionArchive',
  components: {
    Collections,
  },
  data() {
    return {
      activeCollections: [],
      activeEntries: {},

      closedCollections: [],
      closedEntries: {},
    };
  },

  async created() {
    this.featuredPosts = await this.$store.dispatch('getFeaturedPosts');

    let collections;
    let entries;

    ({ collections, entries } = await this.$store.dispatch('getCollections'));
    this.activeCollections = collections;
    this.activeEntries = entries;

    ({ collections, entries } = await this.$store.dispatch('getClosedCollections'));
    this.closedCollections = collections;
    this.closedEntries = entries;
  },
};
</script>

<style lang="sass" scoped>
</style>

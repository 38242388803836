<template>
  <!-- Entry Form
    TODO: Make this dynamic form and not hardcoded
  -->
  <form
    v-if="editMode"
    :class="`entry-form is-status${karma}`"
    @submit.prevent="submitForm"
  >
    <label>
      <span>Payment Type</span>
      <select
        v-model="formData.payment_type"
        name="payment_type"
        :disabled="!!paymentType"
      >
        <option value="Cheque" selected>
          Cheque
        </option>
        <option value="GPay">
          GPay
        </option>
      </select>
    </label>

    <label class="entry__status">
      {{ getStatusName() }}
    </label>

    <label v-if="formData.payment_type === 'Cheque'">
      <span>Transaction / Cheque Number <b>*</b></span>
      <input
        v-model="formData.number"
        type="text"
        name="number"
        required
        placeholder="Cheque number is six digits"
      >
    </label>

    <label v-if="formData.payment_type === 'Cheque'">
      <span>Bank Name</span>
      <input
        v-model="formData.bank"
        type="text"
        name="bank"
        placeholder="e.g. POSB, DBS, UOB, etc"
      >
    </label>

    <label>
      <span>Amount <b>*</b></span>
      <input
        v-model="formData.amount"
        type="number"
        name="amount"
        required
        placeholder="Contribution amount"
      >
    </label>

    <label>
      <span>Date of Cheque / Transaction</span>
      <date-picker
        v-model="formData.written_date"
        type="text"
        name="written_date"
        value-type="format"
        placeholder="DD/MM/YYYY"
        format="DD/MM/YYYY"
      />
    </label>

    <div v-if="formData.payment_type === 'GPay'">
      <span>GPay Photo <b>*</b></span>
      <input
        type="file"
        accept="jpg|jpeg|png"
        name="photo"
        :required="!formData.photo_src"
        @change="parseFile"
      >
      <a
        v-if="formData.photo_src"
        href="#"
        @click="openModal"
      >
        See Photo
      </a>
    </div>

    <div class="entry-form__submit">
      <p v-if="errorMessage" class="entry-form__error">
        {{ errorMessage }}
      </p>
      <input type="submit" value="Submit">
      <a href="#" @click="$emit('closed')">
        Cancel Edit
      </a>
    </div>

    <Loading v-show="isLoading" />
    <Modal
      v-show="isModalOpen"
      :image="formData.photo_src"
      @closed="closeModal"
    />
  </form>

  <!-- Entry Preview -->
  <div v-else-if="karma !== '0'" :class="`entry is-status${karma}`">
    <h6>
      {{ content.payment_type }}
    </h6>
    <label class="entry__status">
      {{ getStatusName() }}
    </label>
    <p class="entry__no">
      <strong>No:</strong> <code>{{ content.number }}</code>
    </p>
    <p class="entry__bank">
      <strong>Bank:</strong> {{ content.bank }}
    </p>
    <p class="entry__amount">
      <strong>Amount:</strong> {{ content.amount }}
    </p>
    <p class="entry__date">
      <strong>Date:</strong> {{ content.written_date }}
    </p>
    <p v-if="content.photo_src" class="entry__photo">
      <strong>Photo: </strong>
      <a @click="openModal">See Photo</a>
    </p>
    <Modal
      v-show="isModalOpen"
      :image="content.photo_src"
      @closed="closeModal"
    />
  </div>

  <!-- If the amount not written -->
  <div v-else>
    <label class="entry__status is-empty">
      Empty
    </label>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Loading from './Loading.vue';
import Modal from './Modal.vue';

export default {
  name: 'EntryForm',
  components: {
    DatePicker,
    Loading,
    Modal,
  },

  props: {
    entry: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      required: false,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    paymentType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      formData: {
        payment_type: this.$props.paymentType || 'Cheque',
        number: '',
        amount: '',
        bank: '',
        written_date: '',
        photo_src: '',
        note: '',
      },

      isLoading: false,
      isModalOpen: false,
      errorMessage: '',

      // photoSrc: this.$props.content.photo_src,
      photoFile: '',
    };
  },

  computed: {
    karma() {
      return this.$props.entry.comment_karma;
    },
  },

  watch: {
    editMode() {
      this.errorMessage = '';
    },
  },

  mounted() {
    if (this.$props.content) {
      this.formData = { ...this.$props.content };
    }
  },

  methods: {
    async submitForm() {
      this.isLoading = true;

      const payload = {
        commentID: this.$props.entry.comment_ID,
        commentContent: JSON.stringify(this.formData),
        commentPhoto: this.photoFile,
      };

      try {
        const isUpdated = await this.$store.dispatch('updateEntry', payload);

        this.isLoading = false;

        // Update the entry in state
        if (isUpdated) {
          await this.$store.commit('updateEntryCache', {
            collectionID: this.$props.entry.comment_post_ID,
            content: this.formData,
            karma: '1', // Karma always back to PENDING after changes
          });
        }

        this.$emit('submitted');
      } catch (error) {
        this.isLoading = false;

        if (error.response) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = error.message;
        }
      }
    },

    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },

    /**
     * Get the File object and add it to the $fields
     */
    parseFile(e) {
      const file = e.currentTarget.files[0];
      this.formData.photo_src = URL.createObjectURL(file);
      this.photoFile = file;
    },

    /**
     * TODO: Make this naming taken from one source
     */
    getStatusName(karma = false) {
      const allKarma = {
        '-1': 'Rejected',
        0: 'Empty',
        1: 'Pending',
        2: 'Approved',
      };

      if (karma === false) {
        return allKarma[this.$props.entry.comment_karma];
      }

      return allKarma[karma];
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/vars"

.entry-form,
.entry
  display: grid
  grid-template-columns: repeat(2, minmax(0,1fr))
  column-gap: 0.5rem
  row-gap: 0.25rem

  padding: 0.5rem
  max-width: 320px
  background-color: var(--formBg, --color1Light)
  box-shadow: var(--shadow0)
  border-radius: var(--gRadius)

  @media ($below-s)
    max-width: none

  &.is-status-1
    --formBg: var(--color3Light)
  &.is-status0
    --formBg: var(--color1Light)
  &.is-status1
    --formBg: var(--color4Light)
  &.is-status2
    --formBg: var(--color2Light)

  select,
  input
    padding: 0.25rem 0.5rem

  label
    grid-column: span 1

.entry-form
  row-gap: 0.5rem
  max-width: 100%

  label > span
    display: block
    margin-bottom: 0.25rem
  b
    color: red

  @media ($below-xs)
    grid-template-columns: repeat(1, minmax(0,1fr))

    label,
    .entry-form__submit
      order: 2
    label.entry__status
      order: 1

.entry-form__submit
  display: flex
  flex-wrap: wrap
  align-items: center
  grid-column: span 2
  margin-top: 0.5rem

  p
    width: 100%

  input[type="submit"]
    padding-top: 0.5rem
    padding-bottom: 0.5rem

  a
    font-size: 12px
    margin-left: auto

  @media ($below-xs)
    grid-column: span 1

.entry__status
  justify-self: flex-end
  align-self: flex-start
  padding: 0.125rem 0.25rem

  border: 1px solid var(--color, var(--text))
  border-radius: var(--gRadius)
  color: var(--color, var(--text))

  font-weight: 700
  font-size: var(--xsmallFontSize)
  text-transform: uppercase
  letter-spacing: 0.05em

  .is-status-1 &
    --color: var(--color3)
  .is-status2 &
    --color: var(--color2)

  &.is-empty
    display: inline-block

.entry-form__error
  margin-bottom: 0.5rem
  color: var(--color3)

</style>

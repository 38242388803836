<template>
  <div class="user-form__wrapper">
    <UserForm
      :errors="errors"
      submit-button="Register"
      form-cache="registerForm"
      form-endpoint="/user/register-form"
      @submitted="register"
    />
    <p class="user-form__note">
      Already have an account?
      <router-link :to="{name: 'UserLogin'}">
        Login Now
      </router-link>
    </p>
  </div>
</template>

<script>
import api from '../helpers-api';
import UserForm from '../components/UserForm.vue';

export default {
  name: 'Register',
  components: {
    UserForm,
  },
  data() {
    return {
      errors: {},
    };
  },

  methods: {
    /**
     * Register new user in Firebase
     */
    async register(payload, payloadFile = []) {
      try {
        const response = await api.post('/user/register', payload);

        // If there is File input, upload it
        if (payloadFile.length > 0) {
          payloadFile.forEach(async (file) => {
            file.userID = response.data;

            await this.$store.dispatch('uploadImage', file);
          });
        }

        this.$router.push({
          name: 'UserLogin',
          query: { afterRegister: true },
        });
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data.message;
        } else {
          // server timeout
          this.errors = `${error.message}. Please try again later.`;
        }
      }
    },

  },
};
</script>

<style lang="sass" scoped>
@import '../sass/vars'

.user-form__wrapper
  --blogWidth: 720px
  --fieldGap: 0.5rem

  @media ($below-s)
    --blogWidth: calc(100% - 1rem)

  @media ($below-xs)
    --fieldGap: 0.25rem

</style>

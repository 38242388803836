<template>
  <ul
    v-if="posts.length"
    :class="`post-list ${extraClasses}`"
  >
    <li
      v-for="(post, index) in posts"
      :key="index"
      class="post"
    >
      <router-link
        :to="{name: 'PostSingle', params: { slug: post.post_name }}"
        class="post__link"
      >
        <figure v-if="post.post_image_thumbnail" class="post__thumbnail">
          <img :src="post.post_image_thumbnail" :alt="post.post_title">
        </figure>
        <dl class="post__summary">
          <dt>
            {{ post.post_title }}
          </dt>
          <time>
            {{ post.post_date }}
          </time>
          <dd>
            {{ post.post_excerpt }}…
          </dd>
        </dl>
      </router-link>
    </li>
  </ul>
  <main v-else class="post-not-found">
    <p class="has-text-align-center">
      There is no more article found
    </p>
  </main>
</template>

<script>
export default {
  name: 'Posts',
  components: {
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
    extraClasses: {
      type: String,
      default: 'alignwide',
    },
  },
  methods: {},
};
</script>

<style lang="sass">
@import "../sass/vars"

.post-list
  display: grid
  grid-template-columns: repeat(var(--postColumns, 3), 1fr)
  row-gap: var(--blockSpacing)
  column-gap: var(--blockSpacing)
  padding-left: 0
  list-style-type: none

  @media ($above-s) and ($below-m)
    --blockSpacing: 1rem

  @media ($below-s)
    --postColumns: 1

.post
  position: relative

.post__link
  display: flex
  flex-direction: column
  row-gap: 1rem

  background-color: var(--textInvert)
  padding: 0.5rem
  border: 1px solid rgba(black, .1)
  height: 100%

  text-decoration: none
  border-radius: var(--gRadius)
  box-shadow: var(--shadow0)

  &:hover
    box-shadow: var(--shadow1)
    color: var(--text)
    transform: translateY(-2px)
  &:active
    transition: none
    transform: none

  @media ($below-s)
    flex-direction: row
    column-gap: 0.75rem

.post__thumbnail
  width: 100%
  height: 100px

  img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center center

  @media ($below-s)
    width: 75px
    height: 100%

.post__summary
  flex: 1

  dt
    font-weight: 700
    font-family: var(--hFontFamily)
    font-size: var(--h6Size)
    line-height: var(--hLineHeight)

    .post__link:hover &
      text-decoration: underline

  time
    display: block
    margin-bottom: 0.5rem
    font-size: var(--xsmallFontSize)

  dd
    font-size: var(--xsmallFontSize)
</style>

<template>
  <div class="user-form__wrapper">
    <div v-if="$route.query.afterRegister" class="toast is-register-success">
      <p>
        <strong>Registration Successful</strong>
      </p>
      <p>
        Thank you, your registration to PCare has been successfully submitted.
      </p>
      <p>
        We will notify you via email after we have reviewed your submission.
      </p>
    </div>
    <div v-if="$route.query.redirectTo" class="toast is-require-login">
      Please login to visit that page.
    </div>
    <div v-if="$route.query.message" class="toast is-message">
      {{ $route.query.message }}
    </div>

    <form class="user-form" @submit.prevent="login">
      <label>
        <span>Email</span>
        <input
          v-model="email"
          type="email"
          placeholder="you@email.com"
        >
      </label>
      <label>
        <span>Password</span>
        <input v-model="password" type="password">
      </label>

      <div v-if="message" :class="`user-form__message is-${messageType}`">
        {{ message }}
      </div>

      <div class="user-form__submit">
        <button type="submit">
          Log In
        </button>
      </div>
    </form>
    <p class="user-form__note">
      <router-link :to="{name: 'UserRegister'}">
        Register Now
      </router-link>
      |
      <router-link :to="{name: 'UserForgotPassword'}">
        Forgot Password?
      </router-link>
    </p>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '../components/Loading.vue';

export default {
  name: 'Login',
  components: {
    Loading,
  },
  data() {
    return {
      email: '',
      password: '',

      message: '',
      messageType: 'error', // or success
      isLoading: false,
    };
  },

  methods: {
    /**
     * Authorize user
     */
    async login() {
      this.message = '';
      this.isLoading = true;

      try {
        const response = await axios.post(`${process.env.VUE_APP_AUTH_URL}/token`, {
          username: this.email,
          password: this.password,
        });

        await this.saveLoginData(response.data);
        this.isLoading = false;

        // if redirected to login from secured page, redirect back after logging in
        if (this.$route.query.redirectTo) {
          this.$router.push({ name: this.$route.query.redirectTo });
        } else {
          this.$router.push({ name: 'Home' });
        }
      } catch (error) {
        this.isLoading = false;
        this.message = 'Email or password is wrong';
      }
    },

    /**
     * Save the data to local storage after loggin in
     */
    async saveLoginData(data) {
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('token', data.token);
      localStorage.setItem('displayName', data.user_display_name);
      localStorage.setItem('email', data.user_email);

      await this.$store.commit('loginUser', {
        token: data.token,
        email: data.user_email,
        displayName: data.user_display_name,
      });

      return true;
    },
  },
};
</script>

<style lang="sass">
@import "../sass/vars"

.user-form__wrapper
  --blogWidth: 320px

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: var(--blogWidth)
  margin: 0 auto

  span
    display: block
    margin-bottom: 0.25rem
    font-weight: 600
    font-size: var(--smallFontSize)
    text-transform: uppercase
  a
    text-decoration: underline
  a:hover
    color: var(--color1Dark)

.user-form
  display: flex
  flex-direction: column
  row-gap: 1rem
  column-gap: 1rem

  width: var(--blogWidth)
  background-color: var(--textInvert)
  padding: 1.5rem

  border: 1px solid rgba(--text, .3)
  border-radius: var(--gRadius)
  box-shadow: var(--shadow0)

  @media ($below-xs)
    padding: 0.5rem

.user-form__message
  padding: 0.5rem
  margin-right: 0.5rem
  margin-left: 0.5rem
  border-radius: 4px
  font-size: var(--smallFontSize)

  &.is-error
    color: #D32F2F
    background-color: #FFEBEE
  &.is-success
    color: #388E3C
    background-color: #E8F5E9

  a
    text-decoration: none
    pointer-events: none // disable the Login link when email is duplicate

.user-form__note
  margin-top: 0.5rem
  margin-bottom: 0.5rem
  text-align: center

.toast
  display: inline-block
  width: var(--blogWidth)
  padding: 0.5rem
  margin-bottom: 1rem
  border-left: 4px solid var(--color1)
  background-color: var(--textInvert)

  font-size: var(--smallFontSize)
  box-shadow: var(--shadow0)

  * + *
    margin-top: 0.75rem
</style>

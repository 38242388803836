<template>
  <div class="user-form__wrapper">
    <div class="toast">
      Please enter your username or email address. You will receive an email message with instructions on how to reset your password.
    </div>

    <form class="user-form" @submit.prevent="requestPasswordReset">
      <label>
        <span>Email</span>
        <input
          v-model="email"
          type="email"
          placeholder="you@email.com"
        >
      </label>

      <div v-show="message" :class="`user-form__message is-${messageType}`">
        {{ message }}
      </div>

      <div class="user-form__submit">
        <button type="submit">
          Get New Password
        </button>
      </div>
    </form>
    <p class="user-form__note">
      <router-link :to="{name: 'UserLogin'}">
        Back to Login
      </router-link>
    </p>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import api from '../helpers-api';
import Loading from '../components/Loading.vue';

export default {
  name: 'ForgotPassword',
  components: {
    Loading,
  },
  data() {
    return {
      email: '',
      isLoading: false,
      message: '',
      messageType: 'error', // or success
    };
  },
  methods: {
    /**
     * Request Firebase to send email containing password reset link
     */
    async requestPasswordReset() {
      this.isLoading = true;

      try {
        const response = await api.post('/user/forgot-password', {
          email: this.email,
        });

        this.isLoading = false;
        this.message = response.data.message;
        this.messageType = 'success';
      } catch (error) {
        this.isLoading = false;
        if (error.response) {
          this.message = error.response.data.message;
          this.messageType = 'error';
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>

<template>
  <div class="loading">
    <span />
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="sass" scoped>
.loading
  top: 0
  left: 0
  z-index: 101
  height: 100%
  width: 100%
  background-color: rgba(black, .5)
  position: fixed
  display: flex
  justify-content: center
  align-items: center

  @keyframes spin
    to
      transform: rotateZ(360deg)

  span
    display: block
    width: 60px
    height: 60px
    margin: 0 auto
    border: 3px solid transparent
    border-top-color: #fff
    border-bottom-color: #fff
    border-radius: 50%
    animation: spin ease 1000ms infinite
</style>

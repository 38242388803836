import Vue from 'vue';

import App from './App.vue';
import Layout from './components/Layout.vue';
import LayoutLogin from './components/LayoutLogin.vue';

import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.component('Layout', Layout);
Vue.component('LayoutLogin', LayoutLogin);

let app;
if (!app) {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

<template>
  <footer class="footer">
    <div class="footer-row is-footer-bottom" data-columns="1">
      <div class="footer-column">
        <p class="copyright">
          Copyright {{ year }} All Rights Reserved
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  components: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/vars'

.footer
  background-color: var(--text)
  margin-top: auto

  h3
    font-size: var(--h4Size)

.footer-row
  display: flex
  justify-content: center
  column-gap: var(--blockSpacing)
  row-gap: var(--blockSpacing)

  max-width: var(--siteWidth)
  margin: 0 auto
  padding-top: var(--footerPadding, 2rem)
  padding-bottom: var(--footerPadding, 2rem)
  background-color: transparent
  color: var(--textInvert)

  &.is-footer-bottom
    --footerPadding: 0.25rem
    font-size: var(--smallFontSize)

  @media ($below-nav)
    flex-direction: column

.footer-column
  display: flex
  flex-direction: column
  flex: 1
  padding: 0
  height: 100%
  list-style-type: none

  > * + *
    margin-top: 1rem

  @media ($below-s)
    width: 100%
    align-items: center

.copyright
  width: 100%
  text-align: center
  opacity: .75

</style>

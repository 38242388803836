import axios from 'axios';

const axiosArgs = {
  baseURL: process.env.VUE_APP_API_URL,
};

const api = axios.create(axiosArgs);

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default api;

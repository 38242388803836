import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';
import Home from './views/Home.vue';
import UserProfile from './views/UserProfile.vue';
import UserLogin from './views/UserLogin.vue';
import UserForgotPassword from './views/UserForgotPassword.vue';
import UserRegister from './views/UserRegister.vue';
import CollectionArchive from './views/CollectionArchive.vue';
import CollectionSingle from './views/CollectionSingle.vue';
import CollectionCreate from './views/CollectionCreate.vue';
import CollectionEdit from './views/CollectionEdit.vue';
import PostArchive from './views/PostArchive.vue';
import PostSingle from './views/PostSingle.vue';
import Page from './views/Page.vue';
import NotFound from './views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      title: 'Profile',
    },
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: 'Login',
      noAuthRequired: true,
      layout: 'LayoutLogin',
    },
  },
  {
    path: '/register',
    name: 'UserRegister',
    component: UserRegister,
    meta: {
      title: 'Register',
      noAuthRequired: true,
      layout: 'LayoutLogin',
    },
  },
  {
    path: '/forgot-password',
    name: 'UserForgotPassword',
    component: UserForgotPassword,
    meta: {
      title: 'Forgot Password',
      noAuthRequired: true,
      layout: 'LayoutLogin',
    },
  },
  {
    path: '/page/:slug',
    name: 'Page',
    component: Page,
    meta: {
      title: 'Loading...',
    },
  },
  {
    path: '/blog',
    name: 'PostArchive',
    component: PostArchive,
    meta: {
      title: 'All News',
    },
  },
  {
    path: '/blog/:slug',
    name: 'PostSingle',
    component: PostSingle,
    meta: {
      title: 'Loading...',
    },
  },
  {
    path: '/collections',
    name: 'CollectionArchive',
    component: CollectionArchive,
    meta: {
      title: 'All Collections',
    },
  },
  {
    path: '/collections/:id',
    name: 'CollectionSingle',
    component: CollectionSingle,
    meta: {
      title: 'Collection',
      adminOnly: true,
    },
  },
  {
    path: '/collections/create',
    name: 'CollectionCreate',
    component: CollectionCreate,
    meta: {
      title: 'Create Collection',
      adminOnly: true,
    },
  },
  {
    path: '/collections/edit/:id',
    name: 'CollectionEdit',
    component: CollectionEdit,
    meta: {
      title: 'Edit Collections',
      adminOnly: true,
    },
  },
  {
    path: '/wp-admin',
    name: 'Admin',
    beforeEnter() {
      window.location.href = 'https://admin.pcare.sg/wp-admin';
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior() {
    const $app = document.getElementById('app');
    if ($app) {
      $app.scrollIntoView({ behavior: 'smooth' });
    }
  },
});

// Set SEO metatag
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | PCare`;
  next();
});

// Check if page is admin only
router.beforeEach((to, from, next) => {
  // if auth required, but not logged in
  if (!to.meta.noAuthRequired && !store.state.isLoggedIn) {
    next({
      name: 'UserLogin',
      query: { redirectTo: to.name },
    });
  }

  // if page is admin only, but opened by non-admin
  if (to.meta.adminOnly && !store.state.isAdmin) {
    next({
      name: 'Home',
      query: { notAllowed: true },
    });
  }

  next();
});

export default router;
